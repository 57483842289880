import axios from "axios"
import store from "@/store"
//import router from "@/router"
//import { authURL } from "@/common"

const axiosPython = axios.create({
  baseURL: process.env.VUE_APP_PYTHON_SERVER
})

//response interceptor
axiosPython.interceptors.response.use(
  (response) => {
    store.dispatch("coreui/loading", false)
    return response
  },
  (error) => {
    store.dispatch("coreui/loading", false)
    manageServerError(error, "python")
    return Promise.reject(error)
  }
)

export { axiosPython }

function manageServerError(error, server) {
  console.log("[Error] Ops, something went wrong in " + server)
  console.log("[Error] Error message " + error)
  store.dispatch("error/serverError", {
    code: 500,
    message: "Sorry, something went wrong in " + server + " server!"
  })

  //if (error.config.url != authURL || router.currentRoute.path != "/error")
  // router.push("/error")
}
