import { axiosPython } from "@/http"
import { parseModels } from "@/common"

const getModels = () => {
  return axiosPython
    .get("/modelsAvailable", { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : null
      var serverError = true
      var models = null
      //server is working
      if (data) {
        models = parseModels(data)
        serverError = false
      }
      //console.log(data);
      return { models, serverError }
    })
    .catch((err) => {
      throw err
    })
}

const loadModel = (model) => {
  return axiosPython
    .get("/loadModel/" + model.id, { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : {}
      var serverError = true
      var models = null
      //server is working
      if (data) {
        models = parseModels(data)
        serverError = false
      }
      //console.log(data);
      return { models, serverError }
    })
    .catch((err) => {
      throw err
    })
}

export const modelService = {
  getModels,
  loadModel
}
