import { graphService } from "@/services"

const state = {
  nodes: [],
  edges: []
}
const mutations = {
  SET_NODES(state, nodes) {
    state.nodes = nodes
  },
  SET_EDGES(state, edges) {
    state.edges = edges
  }
}
const actions = {
  clear({ commit }) {
    commit("SET_NODES", [])
    commit("SET_EDGES", [])
  },
  getGraph({ dispatch }, form) {
    return graphService
      .getGraph(form)
      .then((data) => {
        return dispatch("store", data)
      })
      .catch((err) => {
        console.log(err)
      })
  },

  store({ commit }, data) {
    let isServerAvailable = false
    let nodes = []
    let edges = []
    //server is working
    if (data) {
      isServerAvailable = true
      nodes = data.nodes.map((node, index) => {
        return {
          id: index + 1,
          label: node.id
        }
      })
      edges = data.links.map((link, index) => {
        const from = nodes.find((node) => node.label == link.source).id
        const to = nodes.find((node) => node.label == link.target).id
        return {
          id: index + 1,
          from: from,
          to: to
        }
      })
    }
    commit("SET_NODES", nodes)
    commit("SET_EDGES", edges)

    return isServerAvailable
  }
}
const getters = {
  nodes: (state) => {
    return state.nodes ? state.nodes : []
  },
  edges: (state) => {
    return state.edges ? state.edges : []
  }
}
export const graph = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
