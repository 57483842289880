import { userService } from "@/services"

const state = {
  user: {}
}
const mutations = {
  SET_USER(state, user) {
    state.user = user
  }
}
const actions = {
  clear({ commit }) {
    commit("SET_USER", {})
  },
  getUser({ commit }) {
    return userService
      .getUser()
      .then((data) => {
        if (data) {
          console.log(data)
          commit("SET_USER", data.user)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const getters = {
  user: (state) => {
    return state.user ? state.user : {}
  },
  isAuthenticated: (state) => {
    return Object.keys(state.user).length > 0
  }
}
export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
