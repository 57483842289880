const state = {
  graphModes: [
    {
      id: "GEO",
      descr: "GEO"
    },
    {
      id: "LINEAR",
      descr: "Linear"
    },
    {
      id: "GEO_ORIENT",
      descr: "Geo Oriented"
    }
  ],
  graphLayouts: [
    {
      id: 0,
      descr: "Breadth first"
    },
    {
      id: 1,
      descr: "Circle"
    },
    {
      id: 2,
      descr: "Concentric"
    },
    {
      id: 3,
      descr: "Cose"
    }
  ]
}
const mutations = {}
const actions = {}
const getters = {
  graphModes: (state) => {
    return state.graphModes
  },
  graphLayouts: (state) => {
    return state.graphLayouts
  }
}
export const classification = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
