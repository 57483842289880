import { Context } from "@/common"

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  context: "",
  isHome: false,
  isModel: false,
  isGraph: false,
  isMetric: false,
  isAnalogy: false,
  isItalian: true,
  isLoading: false,
  isCorpusSelected: false,
  isError: false
}

const mutations = {
  TOGGLE_SIDEBAR_DESKTOP(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : "responsive"
  },
  TOGGLE_SIDEBAR_MOBILE(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : "responsive"
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_LANGUAGE(state, language) {
    state.isItalian = language == "it" ? true : false
  },
  SET_CONTEXT(state, context) {
    state.context = context
    switch (context) {
      case Context.Home:
        state.isHome = true
        break
      case Context.Model:
        state.isModel = true
        break
      case Context.Graph:
        state.isGraph = true
        break
      case Context.Metric:
        state.isMetric = true
        break
      case Context.Analogy:
        state.isAnalogy = true
        break
      case Context.Error:
        state.isError = true
        break
      default:
        break
    }
  },
  CLEAR_CONTEXT(state) {
    state.context = ""
    state.isHome = false
    state.isModel = false
    state.isGraph = false
    state.isMetric = false
    state.isAnalogy = false
    state.isCorpusSelected = false
    state.isError = false
  },
  SET_SIDEBAR_MINIMIZE(state, sidebarMinimize) {
    state.sidebarMinimize = sidebarMinimize
  },
  set(state, [variable, value]) {
    if (variable == "sidebarMinimize") {
      //store sidebar status in browser storage
      localStorage.setItem("sidebarMinimize", value)
    }
    state[variable] = value
  }
}
const actions = {
  toggleSidebarDesktop({ commit }) {
    commit("TOGGLE_SIDEBAR_DESKTOP")
  },
  toggleSidebarMobile({ commit }) {
    commit("TOGGLE_SIDEBAR_MOBILE")
  },
  loading({ commit }, isLoading) {
    commit("SET_LOADING", isLoading)
  },
  setContext({ commit }, context) {
    commit("CLEAR_CONTEXT")
    commit("SET_CONTEXT", context)
  },
  clearContext({ commit }) {
    commit("CLEAR_CONTEXT")
  },
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language)
  },
  reloadSidebarState({ commit }) {
    const storedState = localStorage.getItem("sidebarMinimize")
    commit(
      "SET_SIDEBAR_MINIMIZE",
      storedState && storedState === "true" ? true : false
    )
  }
}
const getters = {
  sidebarShow: (state) => {
    return state.sidebarShow
  },
  sidebarMinimize: (state) => {
    return state.sidebarMinimize
  },
  context: (state) => {
    return state.context
  },
  isLoading: (state) => {
    return state.isLoading
  },
  isItalian: (state) => {
    return state.isItalian
  },
  isHome: (state) => {
    return state.isHome
  },
  isModel: (state) => {
    return state.isModel
  },
  isGraph: (state) => {
    return state.isGraph
  },
  isMetric: (state) => {
    return state.isMetric
  },
  isAnalogy: (state) => {
    return state.isAnalogy
  },
  isCorpusSelected: (state) => {
    return state.isCorpusSelected
  },
  isError: (state) => {
    return state.isError
  }
}

export const coreui = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
