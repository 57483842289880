export const DEFAULT_NODE = {
  color: "#084594",
  size: 26
}
/*
 * Colors schemes from:
 * https://observablehq.com/@d3/color-schemes
 *
 */
export const theme = [
  {
    inEdges: 0,
    color: {
      background: "#c6dbef",
      border: "#6baed6"
    },
    size: 18
  },
  {
    inEdges: 1,
    color: "#9ecae1",
    size: 18
  },
  {
    inEdges: 2,
    color: "#6baed6",
    size: 20
  },
  {
    inEdges: 3,
    color: "#4292c6",
    size: 22
  },
  {
    inEdges: 4,
    color: "#2171b5",
    size: 24
  },
  {
    inEdges: 5,
    color: "#084594",
    size: 26
  }
]

export const options = {
  nodes: {
    shape: "dot",
    size: 16,
    font: {
      size: 24
    },
    color: {
      background: "#daebe8"
    },
    borderWidth: 2,
    borderWidthSelected: 2,
    shadow: false,
    opacity: 1
  },
  edges: {
    width: 2,
    shadow: false,
    color: {
      color: "#d9d9d9",
      highlight: "#bdbdbd"
    },
    selectionWidth: (width) => {
      return width
    },
    arrows: {
      to: {
        enabled: true
      }
    },
    smooth: {
      type: "continuous",
      roundness: 0
    }
  },
  interaction: {
    navigationButtons: true,
    keyboard: true
  },
  physics: {
    enabled: false
  }
}

export const affinityFieldsIt = [
  { key: "name", label: "Nome", _style: "width:70%" },
  { key: "weight", label: "Peso", _style: "width:30%" }
]

export const affinityFieldsEn = [
  { key: "name", label: "Name", _style: "width:70%" },
  { key: "weight", label: "Weight", _style: "width:30%" }
]

export const getNode = (nodes, nodeId) => {
  const selectedNode = nodes.find((node) => node.id == nodeId)
  return selectedNode ? selectedNode : null
}

export const getEdge = (edges, edgeId) => {
  const selectedEdge = edges.find((edge) => edge.id == edgeId)
  return selectedEdge ? selectedEdge : null
}

export const getEdges = (node, edges) => {
  var counter = 0
  edges.forEach((edge) => {
    if (edge.to == node.id) counter++
  })
  //console.log(node.label + " " + counter)
  return counter
}

export const getColor = (inEdges) => {
  const selected = theme.find((el) => el.inEdges == inEdges)
  //if (selected) console.log(inEdges + " " + selected.color)
  return selected ? selected.color : DEFAULT_NODE.color
}

export const getSize = (inEdges) => {
  const selected = theme.find((el) => el.inEdges == inEdges)
  //if (selected) console.log(inEdges + " " + selected.size)
  return selected ? selected.size : DEFAULT_NODE.size
}
