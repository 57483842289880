import { axiosPython } from "@/http"
import { parseUser } from "@/common"

const getUser = () => {
  var user = {}
  var serverError = true
  return axiosPython
    .get("/.auth/me", { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : null

      //server is working
      if (data) {
        user = parseUser(data[0])
        serverError = false
      }
      //console.log(data);
      return { user, serverError }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const userService = {
  getUser
}
