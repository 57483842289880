import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from "@coreui/icons"
import {
  cilAt,
  cilHome,
  cilLayers,
  cilLockLocked,
  cilTerminal,
  cilUser,
  cilMap,
  cilLocationPin,
  cilChartLine,
  cilGraph,
  cilCompass,
  cilTags,
  cilAsterisk,
  cilBell,
  cilStar,
  cilDescription,
  cilVector,
  cilBook,
  cilArrowCircleLeft,
  cilCloudUpload,
  cilCheckCircle,
  cilArrowCircleTop,
  cilArrowCircleBottom,
  cilChevronCircleRightAlt,
  cilMove
} from "@coreui/icons"

export const iconsSet = Object.assign(
  { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl },
  {
    cilAt,
    cilHome,
    cilLayers,
    cilLockLocked,
    cilTerminal,
    cilUser,
    cilMap,
    cilLocationPin,
    cilChartLine,
    cilGraph,
    cilCompass,
    cilTags,
    cilAsterisk,
    cilBell,
    cilStar,
    cilDescription,
    cilVector,
    cilBook,
    cilArrowCircleLeft,
    cilCloudUpload,
    cilCheckCircle,
    cilArrowCircleTop,
    cilArrowCircleBottom,
    cilChevronCircleRightAlt,
    cilMove
  }
)
