<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div class="card">
          <CCardHeader>
            <CIcon name="cil-book" />
            {{ $t("landing.models.title") }}
          </CCardHeader>
          <div class="card-body">
            <p v-html="$t('landing.models.body')"></p>
            <p class="section-link">
              <router-link :to="{ name: 'Models' }">
                {{ $t("landing.models.link") }}<chevron-right-icon />
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="card">
          <CCardHeader>
            <CIcon name="cil-graph" />
            {{ $t("landing.graph.title") }}
          </CCardHeader>
          <div class="card-body">
            <p v-html="$t('landing.graph.body')"></p>
            <p class="section-link" v-if="selectedModel">
              <router-link :to="{ name: 'Graph' }">
                {{ $t("landing.graph.link") }} <chevron-right-icon />
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div class="card">
          <CCardHeader>
            <CIcon name="cil-description" />
            {{ $t("landing.affinity.title") }}
          </CCardHeader>
          <div class="card-body">
            <p v-html="$t('landing.affinity.body')"></p>
            <p class="section-link" v-if="selectedModel">
              <router-link :to="{ name: 'Affinity' }">
                {{ $t("landing.affinity.link") }} <chevron-right-icon />
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="card">
          <CCardHeader>
            <CIcon name="cil-vector" />
            {{ $t("landing.analogy.title") }}
          </CCardHeader>
          <div class="card-body">
            <p v-html="$t('landing.analogy.body')"></p>
            <p class="section-link">
              <router-link :to="{ name: 'Analogy' }" v-if="selectedModel">
                {{ $t("landing.analogy.link") }}<chevron-right-icon />
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Context } from "@/common"
import { mapGetters } from "vuex"
export default {
  name: "Home",
  computed: {
    ...mapGetters("metadata", ["selectedModel"])
  },
  created() {
    this.$store.dispatch("coreui/setContext", Context.Home)
    this.$store.dispatch("metadata/setModel", null)
  }
}
</script>
<style scoped>
.material-design-icon > .material-design-icon__svg {
  bottom: -0.17rem;
}

.card-title {
  font-weight: 600;
}

a:not([href]) {
  text-decoration: none;
  background-color: transparent;
  color: #321fdb;
}

a:not([href]):hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
