import { axiosPython } from "@/http"
import { getEdges, getColor, getSize } from "@/common"

const getGraph = (form) => {
  let params =
    form.selectedModel +
    "/" +
    form.width +
    "/" +
    form.iterations +
    "/" +
    form.selectedMode +
    "/[" +
    form.seed +
    "]/json"

  return axiosPython
    .get("/graph/" + params, { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : {}
      var nodes = []
      var edges = []
      var uiNodes = []
      var serverError = true
      //server is working
      if (typeof data !== "string") {
        nodes = data.nodes.map((node, index) => {
          return {
            id: index + 1,
            label: node.id
          }
        })
        edges = data.links.map((link, index) => {
          const from = nodes.find((node) => node.label == link.source).id
          const to = nodes.find((node) => node.label == link.target).id
          return {
            id: index + 1,
            from: from,
            to: to
          }
        })
        uiNodes = nodes.map((node) => {
          const inEdges = getEdges(node, edges)
          return {
            ...node,
            color: getColor(inEdges),
            size: getSize(inEdges)
          }
        })
        serverError = false
      }
      return { nodes: uiNodes, edges, serverError }
    })
    .catch((err) => {
      throw err
    })
}

const getAffinity = (form) => {
  let params =
    form.selectedModel + "/" + form.numberOfWords + "/[" + form.seed + "]/json"
  return axiosPython
    .get("/dist/" + params, { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : {}
      var items = []
      var serverError = true
      if (typeof data !== "string") {
        Object.entries(data).forEach(([key, value]) => {
          items.push({
            name: key,
            weight: value.toFixed(4)
          })
        })
        serverError = false
      }
      return { items, serverError }
    })
    .catch((err) => {
      throw err
    })
}

const getAnalogies = (form) => {
  let params =
    form.selectedModel +
    "/" +
    form.numberOfWords +
    "/" +
    form.word1 +
    "/" +
    form.word2 +
    "/" +
    form.word3 +
    "/json"
  return axiosPython
    .get("/analogy/" + params, { withCredentials: true })
    .then((res) => {
      var data = res.data ? res.data : {}
      var items = []
      var serverError = true
      if (typeof data !== "string") {
        Object.entries(data).forEach(([key, value]) => {
          items.push({
            name: key,
            weight: value.toFixed(4)
          })
        })
        serverError = false
      }
      return { items, serverError }
    })
    .catch((err) => {
      throw err
    })
}

export const graphService = {
  getGraph,
  getAffinity,
  getAnalogies
}
