export const modelFieldsIt = [
  { key: "name", label: "Nome", _style: " width:15%" },
  { key: "description", label: "Descrizione", _style: "width:40%" },
  {
    key: "iter",
    label: "Iter",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "mode",
    label: "Mode",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "ndim",
    label: "Ndim",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "window",
    label: "Window",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "select_model",
    label: "Stato",
    _style: "width:10%",
    sorter: false,
    filter: false
  },
  {
    key: "navigation",
    label: "",
    _style: "width:15%",
    sorter: false,
    filter: false
  }
]

export const modelFieldsEn = [
  { key: "name", label: "Name", _style: "width:15%" },
  { key: "description", label: "Description", _style: "width:40%" },
  {
    key: "iter",
    label: "Iter",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "mode",
    label: "Mode",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "ndim",
    label: "Ndim",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "window",
    label: "Window",
    _style: "width:5%",
    sorter: false,
    filter: false
  },
  {
    key: "select_model",
    label: "Status",
    _style: "width:10%",
    sorter: false,
    filter: false
  },
  {
    key: "navigation",
    label: "",
    _style: "width:15%",
    sorter: false,
    filter: false
  }
]

export const parseModels = (rawModels) =>
  rawModels.map((model) => {
    return {
      id: model.ID,
      name: model.name,
      description: model.description,
      ndim: model.ndim,
      iter: model.iter,
      mode: model.mode,
      window: model.window,
      status: model.status
    }
  })

export const authURL = "/.auth/me"

export const isAssigned = (selectedModel, currentModel) =>
  selectedModel && currentModel.name == selectedModel.name ? true : false

export const clearAssigned = (models) =>
  models.forEach((model) => (model.assigned = false))

export const selectCurrentModel = (models, currentModel) => {
  const clearedModels = clearAssigned(models)
  return clearedModels.splice(currentModel.index, 1, {
    ...currentModel,
    assigned: true
  })
}

export const refreshModels = (models, selectedModel) => {
  return models
    ? models.map((model, index) => {
        return {
          ...model,
          index,
          assigned: isAssigned(selectedModel, model)
        }
      })
    : []
}

export const isModelLoading = (models) => {
  const loadingModels = models.filter((model) => model.status == "loading")
  return loadingModels.length > 0 ? true : false
}
