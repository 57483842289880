<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('coreui/set', ['sidebarShow', 'responsive'])
    ">
    <CSidebarBrand to="/">
      <CIcon name="cil-terminal" size="lg"></CIcon>
      <span class="brand c-sidebar-brand-full">{{ title }}</span>
    </CSidebarBrand>

    <ul class="c-sidebar-nav h-100" style="position: relative">
      <li class="c-sidebar-nav-item">
        <router-link
          :to="{ name: 'Home' }"
          class="c-sidebar-nav-link"
          :class="{ 'c-active': isHome }">
          <CIcon name="cil-home" class="c-sidebar-nav-icon" />{{
            $t("sidebar.home")
          }}
        </router-link>
      </li>
      <li class="c-sidebar-nav-item">
        <router-link
          :to="{ name: 'Models' }"
          class="c-sidebar-nav-link"
          :class="{ 'c-active c-active-warning': isModel }">
          <CIcon name="cil-book" class="c-sidebar-nav-icon" />{{
            $t("sidebar.models")
          }}
        </router-link>
      </li>
      <div v-if="selectedModel">
        <li class="c-sidebar-nav-title">
          {{ selectedModel.name }}
        </li>
        <li class="c-sidebar-nav-item">
          <router-link
            :to="{ name: 'Graph' }"
            class="c-sidebar-nav-link"
            :class="{ 'c-active c-active-success': isGraph }">
            <CIcon name="cil-graph" class="c-sidebar-nav-icon" />{{
              $t("sidebar.graph")
            }}
          </router-link>
        </li>
        <li class="c-sidebar-nav-item">
          <router-link
            :to="{ name: 'Affinity' }"
            class="c-sidebar-nav-link"
            :class="{ 'c-active c-active-danger': isMetric }">
            <CIcon name="cil-description" class="c-sidebar-nav-icon" />{{
              $t("sidebar.affinity")
            }}
          </router-link>
        </li>
        <li class="c-sidebar-nav-item">
          <router-link
            :to="{ name: 'Analogy' }"
            class="c-sidebar-nav-link"
            :class="{ 'c-active c-active-warning': isAnalogy }">
            <CIcon name="cil-vector" class="c-sidebar-nav-icon" />
            {{ $t("sidebar.analogies") }}
          </router-link>
        </li>
      </div>
    </ul>
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    ...mapGetters("metadata", ["selectedModel"]),
    ...mapGetters("coreui", {
      show: "sidebarShow",
      minimize: "sidebarMinimize",
      isHome: "isHome",
      isModel: "isModel",
      isGraph: "isGraph",
      isMetric: "isMetric",
      isAnalogy: "isAnalogy"
    })
  }
}
</script>
<style scoped>
.brand {
  font-size: 1.2em;
  padding-left: 1rem;
}
.c-active-primary {
  border-left: 3px solid#321fdb;
}
.c-active-success {
  border-left: 3px solid#2eb85c;
}
.c-active-warning {
  border-left: 3px solid#f9b115;
}
.c-active-danger {
  border-left: 3px solid#e55353;
}

.c-sidebar-minimized .c-active-primary {
  border-right: 3px solid#321fdb;
}
.c-sidebar-minimized .c-active-success {
  border-right: 3px solid#2eb85c;
}
.c-sidebar-minimized .c-active-warning {
  border-right: 3px solid#f9b115;
}
.c-sidebar-minimized .c-active-danger {
  border-right: 3px solid#e55353;
}
a:hover {
  text-decoration: none;
}
</style>
