const state = {
  selectedModel: null
}

const mutations = {
  SET_SELECTED_MODEL(state, model) {
    state.selectedModel = model
    //store model in browser storage
    localStorage.setItem("model", JSON.stringify(model))
  }
}

const actions = {
  setModel({ commit }, model) {
    commit("SET_SELECTED_MODEL", model)
  },
  reloadModel({ commit }) {
    const storedModel = localStorage.getItem("model")
    commit("SET_SELECTED_MODEL", storedModel ? JSON.parse(storedModel) : null)
  }
}

const getters = {
  selectedModel: (state) => {
    return state.selectedModel
  }
}

export const metadata = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
