import Vue from "vue"
import Vuex from "vuex"

import { error } from "./modules/error"
import { coreui } from "./modules/coreui"
import { message } from "./modules/message"
import { metadata } from "./modules/metadata"

import { graph } from "./modules/graph/graph"
import { classification } from "./modules/classification/classification"
import { user } from "./modules/user/user"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    error,
    coreui,
    message,
    metadata,
    graph,
    classification,
    user
  }
})
