import Vue from "vue"
import VueRouter from "vue-router"

import Error from "@/views/error/Error"
import Home from "@/views/Home"

Vue.use(VueRouter)

//Vue.http.headers.common['Access-Control-Allow-Origin'] ="*";

const routes = [
  {
    path: "/error",
    component: Error,
    meta: {
      authorize: []
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      authorize: []
    }
  },
  {
    path: "/models",
    name: "Models",
    component: () => import("../views/Models"),
    meta: {
      authorize: []
    }
  },
  {
    path: "/graph",
    name: "Graph",
    component: () => import("../views/graph/Graph"),
    meta: {
      authorize: []
    }
  },
  {
    path: "/affinity",
    name: "Affinity",
    component: () => import("../views/metric/Affinity"),
    meta: {
      authorize: []
    }
  },
  {
    path: "/analogy",
    name: "Analogy",
    component: () => import("../views/metric/Analogy"),
    meta: {
      authorize: []
    }
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
