export const BreadcrumbMap = [
  {
    route: "Home",
    breadCrumbs: [{ path: "sidebar.home", to: "/" }]
  },
  {
    route: "Error",
    breadCrumbs: [{ path: "sidebar.home", to: "/" }]
  },
  {
    route: "Models",
    breadCrumbs: [
      { path: "sidebar.home", to: "/" },
      { path: "sidebar.models", to: "" }
    ]
  },
  {
    route: "Graph",
    breadCrumbs: [
      { path: "sidebar.models", to: "/models" },
      { path: "sidebar.graph", to: "" }
    ]
  },
  {
    route: "Metric",
    breadCrumbs: [
      { path: "sidebar.models", to: "/models" },
      { path: "sidebar.metric", to: "" }
    ]
  },
  {
    route: "Analogy",
    breadCrumbs: [
      { path: "sidebar.models", to: "/models" },
      { path: "sidebar.analogies", to: "" }
    ]
  },
  {
    route: "Affinity",
    breadCrumbs: [
      { path: "sidebar.home", to: "/" },
      { path: "sidebar.affinity", to: "" }
    ]
  }
]
